<template>
    <div>
        <el-row :gutter="20" align="middle">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Start date</p>
                <el-date-picker
                    v-model="filter.start_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">End date</p>
                <el-date-picker
                    v-model="filter.end_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Doctor/Employee Phone Number</p>
                <el-input v-model="filter.phone_number" size="large" placeholder="Select Doctor/Employee Phone number" />
            </el-col>
            <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" class="mt-40">
                <el-button type="primary" size="large" @click="getItems" round
                >Filter
                </el-button
                >
                <el-button type="danger" size="large" @click="clear" round
                >Clear
                </el-button
                >
            </el-col>
        </el-row>

        <br/>

        <el-table :data="items" border v-loading="loading" >
            <el-table-column prop="fullname" label="Full Name"></el-table-column>
            <!-- <el-table-column prop="user_id" label="User ID"></el-table-column> -->
            <el-table-column prop="user_type" label="User Type"></el-table-column>
            <el-table-column prop="phone_number" label="Phone Number"></el-table-column>
            <el-table-column prop="balance" label="Balance"></el-table-column>
        </el-table>
        <el-pagination
            v-model:current-page="currentPage"
            :page-size="per_page"
            layout="total, prev, pager, next"
            :total="total_count"
            @current-change="getItems"
        />

    </div>
</template>
<script>
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import { mapGetters } from 'vuex';
export default {
    name: 'Referral',
    title: 'Referrals',
    mixins: [CenterMixins],
    data() {
        return {
            items: [],
            total_count: 0,
            currentPage: 0,
            per_page: 20,
            filter: {
                start_date: null,
                end_date: null,
                phone_number: ''
            },
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        ...mapGetters('auth', ['userInstitute', 'authUser']),
    },
    methods: {
        getItems(val){
            if (val) {
                const offset = val - 1;
                var correctValue = offset * this.per_page;
            }

            const filter = `?limit=${this.per_page}&offset=${correctValue || 0}
            ${this.filter.start_date ? '&start_date='+this.filter.start_date : ''}
            ${this.filter.end_date ? '&end_date='+this.filter.end_date : ''}${this.filter.phone_number ? '&phone_number='+this.filter.phone_number : ''}`;

            axios.get(`api/v2/referrals/bonus${filter}`)
            .then(res => {
                this.items = res.data.data;
                this.total_count = res.data.total_count;
            })
        },
        clear() {
            this.filter = {};
            this.getItems();
        }
    }
}
</script>
<style>
.w-100 {
  width: 100% !important;
}
</style>